import React, { useRef } from "react"
import { Link } from "gatsby"
import { motion, useInView, useScroll, useTransform } from "framer-motion"

const Pillule = ({ children }) => {
  return (
    <div>
      <span className="rounded-full bg-white px-4 py-1 text-eido-grey-900 shadow-lg">
        {children}
      </span>
    </div>
  )
}

const ProjetCard = ({
  url,
  variants,
  title,
  image,
  isSoon,
  annee,
  role,
  setBgColor,
}) => {
  const ref = useRef(null)
  const container = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "start start"],
  })
  const isInView = useInView(container, { amount: 0.75 })

  const useParallax = (value, distance) => {
    return useTransform(value, [0, 1], [-distance, distance])
  }

  const y = useParallax(scrollYProgress, -15)

  return (
    <Link className="group" to={url} ref={container}>
      <motion.div
        className="wrapper"
        ref={ref}
        whileHover={() => setBgColor(null)}
      >
        <article className="rounded-t-3xl border-4 bg-eido-grey-100 shadow-2xl">
          <motion.div
            layoutId={url}
            className="relative aspect-[1920/1080] w-full overflow-hidden px-4 pt-6"
          >
            <motion.img
              className="w-full"
              alt={"Projet capture"}
              src={image.publicURL}
              style={{ y }}
            />
            <div className="absolute bottom-5 left-12 space-y-2">
              {isSoon && <Pillule>Prochainement</Pillule>}
              {annee && <Pillule>{annee}</Pillule>}
              {role && <Pillule>{role}</Pillule>}
            </div>
          </motion.div>
        </article>
        <div className="relative">
          <h3 className="line-mask mask-colors relative mt-4 mr-4 text-right font-metropolis text-xl font-black uppercase leading-tight md:text-2xl">
            {title}
          </h3>
        </div>
      </motion.div>
    </Link>
  )
}

export default ProjetCard
