import * as React from "react"

import Seo from "../components/seo"
import ProjetCard from "../components/ProjetCard"
import { motion, useInView } from "framer-motion"
import { graphql } from "gatsby"
import { useRef, useState } from "react"

const IndexPage = ({ data }) => {
  const container = useRef(null)
  const isInView = useInView(container, { amount: 1 })
  const [bgColor, setBgColor] = useState("#ffffff")

  const { allMarkdownRemark } = data

  const imageAni = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.6,
      },
    },
    exit: {
      //opacity: 0,
    },
  }
  const containerTextAni = {
    hidden: {
      transition: {
        staggerChildren: 0.1,
        staggerDirection: -1
      }
    },
    show: {
      transition: {
        staggerChildren: 0.1,
        delay: 1,
      },
    },
  }
  const textAni = {
    hidden: {
      x: -15,
      opacity: 0,
    },
    show: {
      x: 0,
      opacity: 1,
      transition: {
        ease: [0.16, 1, 0.3, 1],
      },
    },
    exit: {
      x: -15,
      opacity: 0,
    },
  }

  return (
    <>
      <div className="container mx-auto flex justify-end px-6 md:px-2 mt-10 md:mt-16 md:pb-16">
        <motion.div
          className="max-w-3xl md:text-2xl"
          ref={container}
          variants={containerTextAni}
          initial="hidden"
          animate={isInView ? "show" : "hidden"}
        >
          <motion.h1
            className="line-mask md:mt-20 mask-colors font-bold leading-relaxed dark:text-eido-grey-100"
            variants={textAni}
          >
            Un petit tour de mes réalisations
          </motion.h1>
          <motion.p
            className="mt-4 leading-relaxed dark:text-eido-grey-200"
            variants={textAni}
          >
            Certaines sont en ligne et achevées, d'autres en cours.
            Malheureusement je ne peux pas tout exposer ici. N'hésitez pas à me
            contacter pour en savoir plus. Je réalise également des missions pour des agences web ou directement en entreprise.
          </motion.p>
          <motion.p className={"text-eido-grey-700 text-right font-bold uppercase text-sm"} variants={textAni}>Stephan Giumelli</motion.p>
        </motion.div>
      </div>
      <section
        className="container mx-auto my-6 grid gap-6 px-2 md:mb-32 md:grid-cols-2 md:gap-16 md:pt-16"
        style={bgColor ? { backgroundColor: bgColor } : null}
      >
        {allMarkdownRemark.nodes.map(markdown => {
          return (
            <ProjetCard
              url={markdown.frontmatter.slug}
              key={markdown.frontmatter.slug}
              variants={imageAni}
              image={markdown.frontmatter.image}
              title={markdown.frontmatter.title}
              isSoon={markdown.frontmatter.isSoon}
              annee={markdown.frontmatter.annee}
              role={markdown.frontmatter.role}
              setBgColor={setBgColor}
            />
          )
        })}
      </section>
    </>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="eidolon design" description="Création & réalisation de tout - ou presque - ce qui touche au web." />

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      nodes {
        frontmatter {
          slug
          title
          role
          annee
          image {
            id
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          isSoon
        }
      }
    }
  }
`
